import {
    API__USER_EXISTS,
    API__DATA_SAVED,
    API__USER_ID
} from "../actions/actionTypes";

const apiReducer = (state, action) => {
    switch (action.type) {
        case API__USER_EXISTS:
            return {...state, api: {...state.api, userExists: action.payload}};
        case API__USER_ID:
            return {...state, api: {...state.api, userID: action.payload}};
        case API__DATA_SAVED:
            return {...state, api: {...state.api, dataSaved: action.payload}};
        default:
            return state;
    }
};

export default apiReducer;