import {
    SUPPLYCHAINDATA__NUM_OF_SUPPLIERS,
    SUPPLYCHAINDATA__NUM_OF_SPEND_CATEGORIES,
    SUPPLYCHAINDATA__NUM_OF_SPEND_AMOUNT
} from "../actions/actionTypes";

const supplyChainDataReducer = (state, action) => {
    switch (action.type) {
        case SUPPLYCHAINDATA__NUM_OF_SUPPLIERS:
            return {...state, supplyChainData: {...state.supplyChainData, numOfSuppliers: action.payload}};
        case SUPPLYCHAINDATA__NUM_OF_SPEND_CATEGORIES:
            return {...state, supplyChainData: {...state.supplyChainData, numOfSpendCategories: action.payload}};
        case SUPPLYCHAINDATA__NUM_OF_SPEND_AMOUNT:
            return {...state, supplyChainData: {...state.supplyChainData, numOfSpendAmount: action.payload}};
        default:
            return state;
    }
};

export default supplyChainDataReducer;