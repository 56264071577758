import React, {Component} from 'react';
import InputTooltip from "./input-tooltip";

class FormHeader extends Component {
    render() {
        return (
           <div className="form-header">
               <h4>{this.props.title}</h4>
               {this.props.tooltip && <InputTooltip text={this.props.tooltip}/>}
           </div>
        );
    }
}

export default FormHeader;
