import {
   BASICINFO__NAME,
   BASICINFO__INDUSTRY
} from "../actions/actionTypes";

const basicInfoReducer = (state, action) => {
    switch (action.type) {
        case BASICINFO__NAME:
            return {...state, basicInfo: {...state.basicInfo, name: action.payload}};
        case BASICINFO__INDUSTRY:
            return {...state, basicInfo: {...state.basicInfo, industry: action.payload}};
        default:
            return state;
    }
};

export default basicInfoReducer;