import React, {Component} from 'react';
import {
    Navbar,
    Container,
} from 'reactstrap';
import {ReactComponent as Logo} from '../assets/images/logo.svg';
import {connect} from "react-redux";
import {proceedToStepAction} from '../store/actions/actionCreators';
import {Link} from 'react-router-dom';

/**
 * Navbar component
 * @class
 * @hideconstructor
 */
class AppNavbar extends Component {
    render() {
        const {isFormPage} = this.props;
        return (
            <Navbar className={isFormPage}>
                <Container>
                    <Link to="/" onClick={() => this.props.proceedToStep(1)}>
                        <Logo />
                    </Link>
                </Container>
            </Navbar>
        );
    }
}

const mapStateToProps = state => {
    return {state}
};

const mapDispatchToProps = dispatch => {
    return {
        proceedToStep: (payload) => dispatch(proceedToStepAction(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavbar);