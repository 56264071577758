import React from 'react';
import './App.scss';
import {BrowserRouter as Switch, Route} from "react-router-dom";

import Homepage from "./pages/homepage";
import WhatIsROI from "./pages/what-is-roi";
import Form from "./pages/formpage";


function App() {
  return (
      <Switch>
          <Route exact path="/" component={Homepage}/>
          <Route path="/what-is-roi" component={WhatIsROI} />
          <Route path="/form" component={Form}/>
      </Switch>
    );
}

export default App;
