import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {connect} from "react-redux";
import FormStep from './form-step';

import {fields} from '../constants/form-fields';
import {proceedToStepAction, setUserExistence, setUserID} from '../store/actions/actionCreators';
import axios from "axios";

/**
 * Multi step form component
 * @class
 * @hideconstructor
 */
class AppForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formFields: fields
        };
    }

    /**
     * Proceed to next step and save data to DB
     * @method
     */
    proceedToNextStep = () => {
        const {steps, companyInfo} = this.props.state;
        document.querySelector('.form-wrapper').classList.add('hidden');
        const name = companyInfo.name.trim();
        const email = companyInfo.email.trim();
        if (steps === 1 && name.length > 0 && email.length > 0) {
            axios.post(
                'https://roicalculator.ecovadis.com/api/checkIfUserExists',
                {
                    "user_name": name,
                    "user_email": email
                },
                {
                    headers: {'Content-Type': 'application/json'}
                })
                .then(res => {
                    const {data} = res;
                    if (data.id !== "0") {
                        this.props.setUserExistence(true);
                        this.props.setUserID(data.id);
                    }
                    else this.props.setUserExistence(false);
                })
                .catch(error => {
                    console.log(error)
                });
        }

        setTimeout(() => {
            this.props.proceedToStep(steps + 1);
        }, 300);
    };

    /**
     * Render specific step form
     * @param {Number} step Specific step based on state
     * @return {XML}
     * @method
     */
    renderSteps = (step) => {
        return <FormStep
            proceedToNextStep={this.proceedToNextStep}
            formSteps={this.state.formFields[step - 1]}
        />
    };

    render() {
        const {steps} = this.props.state;
        return (
            <Form>
                {this.renderSteps(steps)}
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {state}
};

const mapDispatchToProps = dispatch => {
    return {
        proceedToStep: (payload) => dispatch(proceedToStepAction(payload)),
        setUserExistence: (payload) => dispatch(setUserExistence(payload)),
        setUserID: (payload) => dispatch(setUserID(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppForm);