import {SET_STEP, API__USER_EXISTS, API__DATA_SAVED, API__USER_ID} from "./actionTypes";

/**
 * Proceed to specific step action function
 * @param {Object} payload Data type and values called from different client action
 * @return {{type, payload: *}}
 * @function
 */
export function proceedToStepAction(payload) {
    return {
        type: SET_STEP,
        payload
    }
}

/**
 * Check if user exists
 * @param {Object} payload Data type and values called from different client action
 * @return {{type, payload: *}}
 * @function
 */
export function setUserExistence(payload) {
    return {
        type: API__USER_EXISTS,
        payload
    }
}
/**
 * Set user ID
 * @param {Object} payload Data type and values called from different client action
 * @return {{type, payload: *}}
 * @function
 */
export function setUserID(payload) {
    return {
        type: API__USER_ID,
        payload
    }
}

/**
 * Set if API is saved
 * @param {Object} payload Data type and values called from different client action
 * @return {{type, payload: *}}
 * @function
 */
export function setApiSaved(payload) {
    return {
        type: API__DATA_SAVED,
        payload
    }
}