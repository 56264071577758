import {
    COMPANYINFO__NAME,
    COMPANYINFO__JOB_TITLE,
    COMPANYINFO__EMAIL,
    COMPANYINFO__EMPLOYEES_AMOUNT,
    COMPANYINFO__EMPLOYEES_AMOUNT_PROCUREMENT
} from "../actions/actionTypes";


const companyInfoReducer = (state, action) => {
    switch (action.type) {
        case COMPANYINFO__NAME:
            return {...state, companyInfo: {...state.companyInfo, name: action.payload}};
        case COMPANYINFO__JOB_TITLE:
            return {...state, companyInfo: {...state.companyInfo, jobTitle: action.payload}};
        case COMPANYINFO__EMAIL:
            return {...state, companyInfo: {...state.companyInfo, email: action.payload}};
        case COMPANYINFO__EMPLOYEES_AMOUNT:
            return {...state, companyInfo: {...state.companyInfo, employeesAmount: action.payload}};
        case COMPANYINFO__EMPLOYEES_AMOUNT_PROCUREMENT:
            return {...state, companyInfo: {...state.companyInfo, employeesAmountProcurement: action.payload}};
        default:
            return state;
    }
};

export default companyInfoReducer;