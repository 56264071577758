import React, {Component} from 'react';
import DefaultLayout from '../layouts/default-layout.js';
import {Container, Button} from 'reactstrap';
import {Link} from 'react-router-dom'

/**
 * Home page component
 * @class
 * @hideconstructor
 */
class Homepage extends Component {
    render() {
        return (
            <DefaultLayout>
                <main className="homepage">
                    <Container>
                        <div className="text-wrapper">
                            <h1>Sustainable Procurement ROI Calculator</h1>
                            <p>
                                Welcome to the first sustainable procurement ROI calculator. As the world leader in
                                business sustainability ratings, we have used our expertise and experience to develop an
                                accurate, thorough, industry-leading model that produces actionable results and
                                insights.
                            </p>
                            <Link to="/what-is-roi">
                                <Button size="lg">Get started</Button>
                            </Link>
                        </div>

                    </Container>
                </main>
            </DefaultLayout>
        );
    }
}

export default Homepage;