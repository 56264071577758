import React, {Component} from 'react';
import {Container} from 'reactstrap';
import {ReactComponent as Logo} from '../assets/images/logo-dark.svg';

/**
 * Footer component
 * @class
 * @hideconstructor
 */
class Footer extends Component {
    render() {
        return (
            <footer className={this.props.isFormPage}>
                <Container>
                    <a href="https://www.ecovadis.com" rel="noopener noreferrer" target="_blank">
                        <Logo/>
                    </a>
                    <p><a href="#" className="ot-sdk-show-settings">Cookies Settings</a> | &copy; 2019 EcoVadis, All right reserved</p>
                </Container>
            </footer>
        );
    }
}

export default Footer;