// import {combineReducers} from "redux";
import reduceReducers from "reduce-reducers";

import stepsReducer from "./steps";
import basicInfoReducer from "./basicInfo";
import companyInfoReducer from "./companyInfo";
import financialDataReducer from "./financialData";
import supplyChainDataReducer from "./supplyChainData";
import ecovadisProgramReducer from "./ecovadisProgram";
import calculationsReducer from "./calculations";
import apiReducer from "./api";

const initialState = {
    steps: 1,
    api: {
        userExists: false,
        userID: "0",
        dataSaved: false,
    },
    basicInfo: {
        name: '',
        industry: ''
    },
    companyInfo: {
        name: '',
        jobTitle: '',
        email: '',
        employeesAmount: 0,
        employeesAmountProcurement: 0
    },
    financialData: {
        currency: '$',
        annualRevenue: 0,
        revenueGrowthForecast: 0,
        operatingMargin: 0,
        operatingExpenses: 0,
        marketCap: 0,
        annualCorporateBorrowing: 0,
        productPortfolio: 0
    },
    supplyChainData: {
        numOfSuppliers: 0,
        numOfSpendCategories: 0,
        numOfSpendAmount: 0
    },
    ecovadisProgram: {
        numOfAssesedSuppliers: 0,
        spendAmountCovered: 0,
        numOfSuppliersToBeReduced: 0,
        numOfFrameworks: 0
    },
    calculations: {
        revenueGrowthFromRep: 0,
        revenueGrowthFromProducts: 0,
        growthAndInnovation: 0,
        hiringExpenseSavingsProcurement: 0,
        attritionExpenseSavingsProcurement: 0,
        increasedProductivityProcurement: 0,
        hiringExpenseSavings: 0,
        attritionExpenseSavings: 0,
        increasedProductivity: 0,
        employeeEngagement: 0,
        streamliningExistingReportingProcesses: 0,
        supplierOperationalCostSavings: 0,
        supplierReductionsRationalization: 0,
        costReduction: 0,
        lowerCostOfCapital: 0,
        revenueLossAvoidance: 0,
        costIncreaseAvoidance: 0,
        marketCapLossAvoidance: 0,
        totalDirect: 0,
        totalAvoided: 0
    }
};
const rootReducer = reduceReducers(
    initialState,
    stepsReducer,
    basicInfoReducer,
    companyInfoReducer,
    financialDataReducer,
    supplyChainDataReducer,
    ecovadisProgramReducer,
    calculationsReducer,
    apiReducer
);

export default rootReducer;

