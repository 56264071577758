import {SET_STEP} from '../actions/actionTypes';
// import {animateScroll as scroll} from 'react-scroll';

const stepsReducer = (state, action) => {
    switch (action.type) {
        case SET_STEP:
            const {steps: currentStep} = state;
            const nextStep = action.payload;

            // scroll to top
            if (!!document.querySelector('main.form-page')) { // !! = if element doesnt exist, it returns false instead of null
                const elTop = document.querySelector('main.form-page').getBoundingClientRect().top;
                const scrollTop = document.documentElement.scrollTop;
                const relativeOffset = elTop + scrollTop;
                // scroll.scrollTo(elTop + scrollTop);
                if (scrollTop > relativeOffset)
                    window.scrollTo({top: relativeOffset, behavior: 'smooth'});
            }

            // progress bar animation
            let elementsToAnimate = [];
            let reverse;
            document.querySelectorAll('.progress[data-step]').classList = "progress transition";
            if (nextStep > currentStep) {
                reverse = false;
                for (let i = currentStep; i <= nextStep; i++)
                    elementsToAnimate.push(document.querySelector(`[data-step="${i}"] .progress`))
            } else if (nextStep < currentStep) {
                reverse = true;
                for (let i = currentStep; i >= nextStep; i--)
                    elementsToAnimate.push(document.querySelector(`[data-step="${i}"] .progress`))
            }
            const lastIndex = elementsToAnimate.length - 1;
            elementsToAnimate.forEach((progressElement, index) => {
                progressElement.classList = "progress transition";
                if (index === 0) {
                    progressElement.classList.add('transition-start');
                    if (reverse)
                        progressElement.classList.add('progress-0');
                    else
                        progressElement.classList.add('progress-100')
                } else if (index === lastIndex) {
                    progressElement.classList.add('transition-end');
                    progressElement.classList.add('progress-50');
                } else {
                    progressElement.classList.add(`transition-middle`);
                    if (reverse)
                        progressElement.classList.add('progress-0');
                    else
                        progressElement.classList.add('progress-100');
                }

                progressElement.classList.add(`transition-delay-${index}`)
            });

            return {...state, steps: action.payload};
        default:
            return state;
    }
};

export default stepsReducer;