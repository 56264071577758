import {
    NUM_OF_EMPLOYEES,
    NUM_OF_EMPLOYEES_PROCUREMENT,
    ANNUAL_REVENUE,
    REVENUE_GROWTH_FORECAST,
    OPERATING_EXPENSES,
    MARKET_CAP,
    ANNUAL_CORPORATE_BORROWING,
    PERCENTAGE_OF_PRODUCT_PORTFOLIO,
    NUM_OF_FRAMEWORKS,
    SPEND_AMOUNT_COVERED,
    NUM_OF_SUPPLIERS_TO_BE_REDUCED
} from "../actions/actionTypes";
import {
    REVENUE_GROWTH_FROM_REP,
    REVENUE_GROWTH_FROM_PRODUCTS,
    HIRING_EXPENSE_SAVINGS_PROCUREMENT,
    ATTRITION_EXPENSE_SAVINGS_PROCUREMENT,
    INCREASED_PRODUCTIVITY_PROCUREMENT,
    HIRING_EXPENSE_SAVINGS,
    ATTRITION_EXPENSE_SAVINGS,
    INCREASED_PRODUCTIVITY,
    STREAMLINING_EXISTING_REPORTING_PROCESSES,
    SUPPLIER_OPERATIONAL_COST_SAVINGS,
    SUPPLIER_REDUCTIONS,
    LOWER_COST_OF_CAPITAL,
    REVENUE_LOSS_AVOIDANCE,
    COST_INCREASE_AVOIDANCE,
    MARKET_CAP_LOSS_AVOIDANCE
} from "../../constants/calculations";


const calculationsReducer = (state, action) => {

    // DIRECT
    const revenueGrowthFromRep = REVENUE_GROWTH_FROM_REP(state.financialData.annualRevenue);
    const revenueGrowthFromProducts = REVENUE_GROWTH_FROM_PRODUCTS(state.financialData.annualRevenue, state.financialData.productPortfolio);
    const growthAndInnovation = revenueGrowthFromRep + revenueGrowthFromProducts;

    const hiringExpenseSavingsProcurement = HIRING_EXPENSE_SAVINGS_PROCUREMENT(state.companyInfo.employeesAmountProcurement, state.financialData.revenueGrowthForecast);
    const attritionExpenseSavingsProcurement = ATTRITION_EXPENSE_SAVINGS_PROCUREMENT(state.companyInfo.employeesAmountProcurement);
    const increasedProductivityProcurement = INCREASED_PRODUCTIVITY_PROCUREMENT(state.companyInfo.employeesAmountProcurement);
    const hiringExpenseSavings = HIRING_EXPENSE_SAVINGS(state.companyInfo.employeesAmount, state.financialData.revenueGrowthForecast);
    const attritionExpenseSavings = ATTRITION_EXPENSE_SAVINGS(state.companyInfo.employeesAmount);
    const increasedProductivity = INCREASED_PRODUCTIVITY(state.companyInfo.employeesAmount);

    const employeeEngagement =  hiringExpenseSavingsProcurement +
                                attritionExpenseSavingsProcurement +
                                increasedProductivityProcurement +
                                hiringExpenseSavings +
                                attritionExpenseSavings +
                                increasedProductivity;

    const streamliningExistingReportingProcesses = STREAMLINING_EXISTING_REPORTING_PROCESSES(state.ecovadisProgram.numOfFrameworks);
    const supplierOperationalCostSavings = SUPPLIER_OPERATIONAL_COST_SAVINGS(state.ecovadisProgram.spendAmountCovered);
    const supplierReductionsRationalization = SUPPLIER_REDUCTIONS(state.ecovadisProgram.numOfSuppliersToBeReduced);
    const costReduction =   streamliningExistingReportingProcesses +
                            supplierOperationalCostSavings +
                            supplierReductionsRationalization;

    const lowerCostOfCapital = LOWER_COST_OF_CAPITAL(state.financialData.annualCorporateBorrowing);

    const totalDirect = growthAndInnovation +
                        employeeEngagement +
                        costReduction +
                        lowerCostOfCapital;

    // AVOIDED
    const revenueLossAvoidance = REVENUE_LOSS_AVOIDANCE(state.financialData.annualRevenue);
    const costIncreaseAvoidance = COST_INCREASE_AVOIDANCE(state.financialData.operatingExpenses);

    const marketCapLossAvoidance = MARKET_CAP_LOSS_AVOIDANCE(state.financialData.marketCap);

    const totalAvoided = revenueLossAvoidance +
                         costIncreaseAvoidance +
                         marketCapLossAvoidance;

    switch (action.type) {
        case NUM_OF_EMPLOYEES:
            return {...state,
                calculations: {
                    ...state.calculations,
                    hiringExpenseSavings: hiringExpenseSavings,
                    attritionExpenseSavings: attritionExpenseSavings,
                    increasedProductivity: increasedProductivity,
                    employeeEngagement: employeeEngagement,
                    totalDirect: totalDirect
            }};
        case NUM_OF_EMPLOYEES_PROCUREMENT:
            return {...state,
                            calculations: {
                                ...state.calculations,
                                hiringExpenseSavingsProcurement: hiringExpenseSavingsProcurement,
                                attritionExpenseSavingsProcurement: attritionExpenseSavingsProcurement,
                                increasedProductivityProcurement: increasedProductivityProcurement,
                                employeeEngagement: employeeEngagement,
                                totalDirect: totalDirect
                            }};
        case ANNUAL_REVENUE:
            return {...state,
                            calculations: {
                                ...state.calculations,
                                revenueGrowthFromRep: revenueGrowthFromRep,
                                revenueGrowthFromProducts: revenueGrowthFromProducts,
                                growthAndInnovation: growthAndInnovation,
                                revenueLossAvoidance: revenueLossAvoidance,
                                totalDirect: totalDirect,
                                totalAvoided: totalAvoided
                            }};
        case REVENUE_GROWTH_FORECAST:
            return {...state,
                            calculations: {
                                ...state.calculations,
                                hiringExpenseSavings: hiringExpenseSavings,
                                hiringExpenseSavingsProcurement: hiringExpenseSavingsProcurement,
                                employeeEngagement: employeeEngagement,
                                totalDirect: totalDirect
                            }};
        case OPERATING_EXPENSES:
            return {...state,
                calculations: {
                    ...state.calculations,
                    costIncreaseAvoidance: costIncreaseAvoidance,
                    totalAvoided: totalAvoided
                }};
        case MARKET_CAP:
            return {...state,
                calculations: {
                    ...state.calculations,
                    marketCapLossAvoidance: marketCapLossAvoidance,
                    totalAvoided: totalAvoided
                }};
        case ANNUAL_CORPORATE_BORROWING:
            return {...state,
                calculations: {
                    ...state.calculations,
                    lowerCostOfCapital: lowerCostOfCapital,
                    totalAvoided: totalAvoided
                }};
        case PERCENTAGE_OF_PRODUCT_PORTFOLIO:
            return {...state,
                calculations: {
                            ...state.calculations,
                            revenueGrowthFromProducts: revenueGrowthFromProducts,
                            growthAndInnovation: growthAndInnovation,
                            totalDirect: totalDirect
                }};
        case NUM_OF_FRAMEWORKS:
            return {...state,
                calculations: {
                            ...state.calculations,
                            streamliningExistingReportingProcesses: streamliningExistingReportingProcesses,
                            costReduction: costReduction,
                            totalDirect: totalDirect
                }};
        case SPEND_AMOUNT_COVERED:
            return {...state,
                calculations: {
                            ...state.calculations,
                            supplierOperationalCostSavings: supplierOperationalCostSavings,
                            costReduction: costReduction,
                            totalDirect: totalDirect
                }};
        case NUM_OF_SUPPLIERS_TO_BE_REDUCED:
            return {...state,
                calculations: {
                            ...state.calculations,
                            supplierReductionsRationalization: supplierReductionsRationalization,
                            costReduction: costReduction,
                            totalDirect: totalDirect
                }};
        default:
            return state;
    }
};

export default calculationsReducer;