export const SET_STEP = 'SET_STEP';

// WHAT IS ROI
export const BASICINFO__NAME = 'BASICINFO__NAME';
export const BASICINFO__INDUSTRY = 'BASICINFO__INDUSTRY';

// STEP 1
export const COMPANYINFO__NAME = 'COMPANYINFO__NAME';
export const COMPANYINFO__JOB_TITLE = 'COMPANYINFO__JOB_TITLE';
export const COMPANYINFO__EMAIL = 'COMPANYINFO__EMAIL';
export const COMPANYINFO__EMPLOYEES_AMOUNT = 'COMPANYINFO__EMPLOYEES_AMOUNT';
export const COMPANYINFO__EMPLOYEES_AMOUNT_PROCUREMENT= 'COMPANYINFO__EMPLOYEES_AMOUNT_PROCUREMENT';

//STEP 2
export const FINANCIALDATA__CURRENCY = 'FINANCIALDATA__CURRENCY';
export const FINANCIALDATA__ANNUAL_REVENUE = 'FINANCIALDATA__ANNUAL_REVENUE';
export const FINANCIALDATA__REVENUE_GROWTH_FORECAST = 'FINANCIALDATA__REVENUE_GROWTH_FORECAST';
export const FINANCIALDATA__OPERATING_MARGIN = 'FINANCIALDATA__OPERATING_MARGIN';
export const FINANCIALDATA__OPERATING_EXPENSES = 'FINANCIALDATA__OPERATING_EXPENSES';
export const FINANCIALDATA__MARKET_CAP = 'FINANCIALDATA__MARKET_CAP';
export const FINANCIALDATA__ANNUAL_CORPORATE_BORROWING = 'FINANCIALDATA__ANNUAL_CORPORATE_BORROWING';
export const FINANCIALDATA__PRODUCT_PORTFOLIO = 'FINANCIALDATA__PRODUCT_PORTFOLIO';

// STEP 3
export const SUPPLYCHAINDATA__NUM_OF_SUPPLIERS = 'SUPPLYCHAINDATA__NUM_OF_SUPPLIERS';
export const SUPPLYCHAINDATA__NUM_OF_SPEND_CATEGORIES = 'SUPPLYCHAINDATA__NUM_OF_SPEND_CATEGORIES';
export const SUPPLYCHAINDATA__NUM_OF_SPEND_AMOUNT = 'SUPPLYCHAINDATA__NUM_OF_SPEND_AMOUNT';

// STEP 4
export const ECOVADISPROGRAM__NUM_OF_ASSESED_SUPPLIERS = 'ECOVADISPROGRAM__NUM_OF_ASSESED_SUPPLIERS';
export const ECOVADISPROGRAM__SPEND_AMOUNT_COVERED = 'ECOVADISPROGRAM__SPEND_AMOUNT_COVERED';
export const ECOVADISPROGRAM__NUM_OF_SUPPLIERS_TO_BE_REDUCED = 'ECOVADISPROGRAM__NUM_OF_SUPPLIERS_TO_BE_REDUCED';
export const ECOVADISPROGRAM__NUM_OF_FRAMEWORKS = 'ECOVADISPROGRAM__NUM_OF_FRAMEWORKS';

// GROWTH AND INNOVATION
export const NUM_OF_EMPLOYEES = 'NUM_OF_EMPLOYEES';
export const NUM_OF_EMPLOYEES_PROCUREMENT = 'NUM_OF_EMPLOYEES_PROCUREMENT';

export const ANNUAL_REVENUE = 'ANNUAL_REVENUE';
export const REVENUE_GROWTH_FORECAST = 'REVENUE_GROWTH_FORECAST';
export const OPERATING_EXPENSES = 'OPERATING_EXPENSES';
export const MARKET_CAP = 'MARKET_CAP';
export const ANNUAL_CORPORATE_BORROWING = 'ANNUAL_CORPORATE_BORROWING';
export const PERCENTAGE_OF_PRODUCT_PORTFOLIO = 'PERCENTAGE_OF_PRODUCT_PORTFOLIO';

export const NUM_OF_FRAMEWORKS = 'NUM_OF_FRAMEWORKS';
export const SPEND_AMOUNT_COVERED = 'SPEND_AMOUNT_COVERED';
export const NUM_OF_SUPPLIERS_TO_BE_REDUCED = 'NUM_OF_SUPPLIERS_TO_BE_REDUCED';

// API
export const API__USER_EXISTS = 'API__USER_EXISTS';
export const API__USER_ID = 'API__USER_ID';
export const API__DATA_SAVED = 'API__DATA_SAVED';