import React from "react";
import ReactExport from "react-export-excel";
import {Button} from 'reactstrap';
import {connect} from "react-redux";


class ExcelExport extends React.Component {

    render() {
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

        const dataSet = [];
        const stateCategoriesKeys = Object.keys(this.props).filter(state => state !== 'api' && state !== 'dispatch' && state !== 'steps');
        stateCategoriesKeys.forEach((categoryKey, index) => {

            const sentenceCaseText = (str) => {
                let result = str.replace( /([A-Z])/g, " $1" );
                return result.charAt(0).toUpperCase() + result.slice(1);
            };

            const categoryKeyEdited = sentenceCaseText(categoryKey);

            dataSet.push({
                ySteps: index === 0 ? 0 : 1,
                columns: [categoryKeyEdited],
                data: []
            });

            if (categoryKey === 'calculations') {
                const allowedCalculations = [
                    "growthAndInnovation",
                    "employeeEngagement",
                    "costReduction",
                    "lowerCostOfCapital",
                    "revenueLossAvoidance",
                    "costIncreaseAvoidance",
                    "marketCapLossAvoidance"
                ];

                const editedCalculations = allowedCalculations.map(sentenceCaseText);

                const calculationsColumns = Object.keys(this.props.calculations).filter(val => allowedCalculations.includes(val));
                const calculationsValues = [];
                calculationsColumns.forEach(col => calculationsValues.push(this.props.calculations[col]));

                dataSet.push({
                    columns: editedCalculations,
                    data: [calculationsValues]
                });
            } else {
                const editedLabels = Object.keys(this.props[categoryKey]).map(sentenceCaseText);

                dataSet.push({
                    columns: editedLabels,
                    data: [Object.values(this.props[categoryKey])]
                });
            }
        });

        return (
            <ExcelFile filename="EcoVadis Sustainable Procurement ROI" element={<Button>Export as XLSX</Button>}>
                <ExcelSheet dataSet={dataSet} name="ROI Calculations" />
            </ExcelFile>
        );
    }
}

const mapStateToProps = (state) => {
    return {...state};
};

export default connect(mapStateToProps)(ExcelExport);