import React, {Component} from 'react';
import DefaultLayout from '../layouts/default-layout.js';
import Select from 'react-select';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Container,
    Button,
    Form
} from 'reactstrap';

/**
 * What is roi page component
 * @class
 * @hideconstructor
 */
class WhatIsROI extends Component {

    /**
     * Get static vlaues from input
     * @param {String} val Value of specific industry from input
     * @return {String} value of specific industry from the list
     * @method
     */
    getOptions = (val = false) => {
        const options = [
            {value: "Accommodations", label: "Accommodations"},
            {value: "Accounting", label: "Accounting"},
            {value: "Advertising", label: "Advertising"},
            {value: "Aerospace", label: "Aerospace"},
            {value: "Agriculture & Agribusiness", label: "Agriculture & Agribusiness"},
            {value: "Air Transportation", label: "Air Transportation"},
            {value: "Apparel & Accessories", label: "Apparel & Accessories"},
            {value: "Auto", label: "Auto"},
            {value: "Banking", label: "Banking"},
            {value: "Beauty & Cosmetics", label: "Beauty & Cosmetics"},
            {value: "Biotechnology", label: "Biotechnology"},
            {value: "Chemical", label: "Chemical"},
            {value: "Communications", label: "Communications"},
            {value: "Computer", label: "Computer"},
            {value: "Construction", label: "Construction"},
            {value: "Consulting", label: "Consulting"},
            {value: "Consumer Products", label: "Consumer Products"},
            {value: "Education", label: "Education"},
            {value: "Electronics", label: "Electronics"},
            {value: "Employment", label: "Employment"},
            {value: "Energy", label: "Energy"},
            {value: "Engineering", label: "Engineering"},
            {value: "Entertainment & Recreation", label: "Entertainment & Recreation"},
            {value: "Fashion", label: "Fashion"},
            {value: "Financial Services", label: "Financial Services"},
            {value: "Food & Beverage", label: "Food & Beverage"},
            {value: "Health", label: "Health"},
            {value: "Hospitality", label: "Hospitality"},
            {value: "Information", label: "Information"},
            {value: "Information Technology", label: "Information Technology"},
            {value: "Infrastructure", label: "Infrastructure"},
            {value: "Insurance", label: "Insurance"},
            {value: "Journalism & News", label: "Journalism & News"},
            {value: "Legal Services", label: "Legal Services"},
            {value: "Manufacturing", label: "Manufacturing"},
            {value: "Media & Broadcasting", label: "Media & Broadcasting"},
            {value: "Medical Devices & Supplies", label: "Medical Devices & Supplies"},
            {value: "Mining & Metals", label: "Mining & Metals"},
            {value: "Motion Pictures & Video", label: "Motion Pictures & Video"},
            {value: "Music", label: "Music"},
            {value: "NGO", label: "NGO"},
            {value: "Non-profit/Charity", label: "Non-profit/Charity"},
            {value: "Oil & Gas", label: "Oil & Gas"},
            {value: "Paper & Cardboard", label: "Paper & Cardboard"},
            {value: "Plastics & Containers", label: "Plastics & Containers"},
            {value: "Pharmaceutical", label: "Pharmaceutical"},
            {value: "Private Equity", label: "Private Equity"},
            {value: "Professional Services", label: "Professional Services"},
            {value: "Public Administration", label: "Public Administration"},
            {value: "Public Relations", label: "Public Relations"},
            {value: "Publishing", label: "Publishing"},
            {value: "Real Estate", label: "Real Estate"},
            {value: "Retail", label: "Retail"},
            {value: "Service", label: "Service"},
            {value: "Sports", label: "Sports"},
            {value: "Technology", label: "Technology"},
            {value: "Telecommunications", label: "Telecommunications"},
            {value: "Tourism", label: "Tourism"},
            {value: "Transportation", label: "Transportation"},
            {value: "Travel", label: "Travel"},
            {value: "Utilities", label: "Utilities"},
            {value: "Video Game", label: "Video Game"},
            {value: "Web Services", label: "Web Services"},
            {value: "Other", label: "Other"},
        ];
        if (val)
            return options.find(option => option.value === val);

        return options
    };

    /**
     * Add value to store on input change
     * @param {Object} e Event passed to input
     * @method
     */
    handleChange = (e) => {
        let value, type;
        if (e.type && e.type === 'select') {
            value = e.value;
            type = e.name;
        } else {
            value = e.target.value;
            type = e.target.name;
        }
        this.props.dispatch({type, payload: value});
    };

    /**
     * Binding value from store
     * @param {String} ref Reference to specific action in store
     * @param {String} type Type of the input
     * @return {*}
     */
    bindValueFromStore = (ref, type = 'input') => {
        const stepObject = ref.split('.')[0];
        const objectField = ref.split('.')[1];
        const storeValue = this.props.state[stepObject][objectField];

        if (storeValue === '' || storeValue === 0) {
            return '';
        } else {
            if (type === 'select')
                return {label: this.getOptions(storeValue).label, value: storeValue};

            return storeValue;
        }
    };

    render() {
        const options = this.getOptions();
        return (
            <DefaultLayout>
                <main className="what-is-roi">
                    <Container>
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="text-wrapper">
                                    <h1>What’s the ROI?</h1>
                                    <p>
                                        Complete the following questions to produce your sustainable procurement ROI
                                        forecast. Your answers are private and will be used for this calculation only.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <h2>Begin your sustainable procurement ROI forecast</h2>
                        <Form>
                            <Row>
                                <Col xs={12} sm={12} md={4} offset-md={1}>
                                    <FormGroup className="mb-md-0">
                                        <Label for="company-name">Company name</Label>
                                        <Input
                                            type="text"
                                            name="BASICINFO__NAME"
                                            id="BASICINFO__NAME"
                                            placeholder="Enter company name"
                                            onChange={this.handleChange}
                                            value={this.bindValueFromStore('basicInfo.name')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={12} md={4} offset-md={1}>
                                    <FormGroup className="mb-md-0">
                                        <Label for="BASICINFO__INDUSTRY">Industry</Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={options}
                                            placeholder="Select industry..."
                                            value={this.bindValueFromStore('basicInfo.industry', 'select')}
                                            onChange={(selectedOption) => this.handleChange({
                                                ...selectedOption,
                                                type: 'select',
                                                name: 'BASICINFO__INDUSTRY'
                                            })}
                                        />

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Link to="/form">
                                            <Button>Start</Button>
                                        </Link>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </main>
            </DefaultLayout>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log('State', state);
    return {state}
};

export default connect(mapStateToProps)(WhatIsROI);