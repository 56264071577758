import React, {Component} from 'react';
import {Input} from 'reactstrap';
import {connect} from 'react-redux';

/**
 * Single input component
 * @class
 * @hideconstructor
 */
class FormInput extends Component {

    /**
     * Update state on change
     * @param {Object} e Event that we pass to the function
     * @method
     */
    handleChange = (e) => {
        const {value} = e.target;
        const type = e.target.getAttribute('name');
        this.props.dispatch({type, payload: value});
    };

    /**
     * Redirect to specific calculation on blur
     * @param {String} actionRef Reference to specific calculation
     * @method
     */
    calculate = (actionRef) => {
        this.props.dispatch({type: actionRef})
    };

    /**
     * Bind values from Redux store
     * @param {String} ref Reference to specific calculation
     * @return {Object}
     * @method
     */
    bindValueFromStore = (ref) => {
        const stepObject = ref.split('.')[0];
        const objectField = ref.split('.')[1];
        const storeValue = this.props.state[stepObject][objectField];

        if (storeValue === '' || storeValue === 0) {
            return '';
        } else {
            return storeValue;
        }
    };

    render() {
        const {field} = this.props;
        const inputProps = {
            type: field.type,
            name: field.id,
            id: field.id,
            placeholder: field.placeholder,
            onChange: this.handleChange,
            onBlur: () => {this.calculate(field.calcRef)},
            value: this.bindValueFromStore(field.storeRef),
        };
        if (field.type === 'select') {
            return (
                <Input {...inputProps}>
                    {field.options.map((opt, i) => (
                        <option value={opt} key={i}>{opt}</option>
                    ))}
                </Input>
            )
        }
        return <Input {...inputProps} />
    }
}

const mapStateToProps = (state) => {
    return {state}
};

export default connect(mapStateToProps)(FormInput);